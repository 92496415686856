import _ from "lodash";

import { Workspaces, WorkspaceUsers } from "../../../api";
import { createToast, ToastType } from "../../../common/components/toasts";
import { ApiMode } from "../../types";

const processWorkspaceAction = async ({
  request,
  mode,
}: {
  request: Request;
  mode: ApiMode;
}): Promise<ToastType[]> => {
  const toasts: ToastType[] = [];
  const isCsvImportRequest = request.headers
    .get("Content-Type")
    ?.startsWith("multipart/form-data");
  if (isCsvImportRequest) {
    await WorkspaceUsers.importCsv(request);
    toasts.push(
      createToast({
        header: "Successfully imported workspace users",
        body: `Users have been added to the workspace.`,
      })
    );
  } else {
    const values = await request.json();
    const entity = _.get(values, "entity");
    _.unset(values, "entity");
    if (entity === "workspace-user") {
      if (request.method === "POST") {
        await WorkspaceUsers.create(values);
        toasts.push(
          createToast({
            header: "Successfully created workspace user",
            body: `User with ID ${values.userId} has been added to workspace with ID ${values.workspaceId}.`,
          })
        );
      } else if (request.method === "DELETE") {
        await WorkspaceUsers.delete(values);
        toasts.push(
          createToast({
            header: "Successfully removed workspace user",
            body: `User with ID ${values.userId} has been removed from workspace with ID ${values.workspaceId}.`,
          })
        );
      } else if (request.method === "PATCH") {
        await WorkspaceUsers.update(values);
        toasts.push(
          createToast({
            header: "Successfully updated workspace user",
            body: `User with ID ${values.userId} in workspace with ID ${values.workspaceId} has been updated.`,
          })
        );
      }
    } else if (entity === "workspace") {
      if (request.method === "PATCH") {
        await Workspaces.update(values, mode);
        toasts.push(
          createToast({
            header: "Successfully updated workspace",
            body: `Workspace withith ID ${values.workspaceId} has been updated.`,
          })
        );
      }
    } else if (entity === "invoice") {
      if (request.method === "POST") {
        await Workspaces.sendManualInvoiceRequest(values);
        toasts.push(
          createToast({
            header: "Successfully sent invoice request",
            body: `Invoice request for workspace ${values.workspaceId} has been sent to ${_.join(values.receipientEmails, ",")}.`,
          })
        );
      }
    }
  }
  return toasts;
};

export const workspaceAction =
  (mode: ApiMode) =>
  async ({ request }: { request: Request }) => {
    const toasts: ToastType[] = [];
    try {
      toasts.push(...(await processWorkspaceAction({ request, mode })));
    } catch (error) {
      if (error instanceof Error) {
        toasts.push(
          createToast({
            header: error.name,
            body: error.message,
            type: "danger",
          })
        );
      } else {
        throw error;
      }
    }
    return { toasts };
  };
